import { useState, createContext } from 'react';

import moment from 'moment';

const GraphDatesContext = createContext();

export const GraphDatesProvider = ({ children }) => {
    // TopBar
    const todayDate = new Date();
    // always start at 1st Jan of previous year
    const [startDate, setStartDate] = useState(new Date(todayDate.getFullYear() - 1, 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const getDateFormatForApi = (date) => {
        return moment(date).format('YYYY-MM-DD');
    }
    const getDateFormatForFrontend = (date) => {
        return moment(date).format('DD-MM-YYYY');
    }
    const subtractDaysFromDate = (date, days) => {
        let d = moment(date);
        return d.subtract(days, 'days').toDate();
    } 

    // Buttons
    const [btnSelection, setBtnSelection] = useState(null);
    const [isLegendTrue, setisLegendTrue] = useState(false);

    return <GraphDatesContext.Provider value={{
        startDate, setStartDate,
        endDate, setEndDate,
        todayDate,
        getDateFormatForApi,
        getDateFormatForFrontend,
        subtractDaysFromDate,
        btnSelection, setBtnSelection,
        isLegendTrue, setisLegendTrue,
    }}>
        {children}
    </GraphDatesContext.Provider>
}

export default GraphDatesContext;