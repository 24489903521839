import GraphDatesContext from '../GraphDatesContext';
import { useContext } from 'react'

function Buttons() {
    const {
        btnSelection, setBtnSelection,
        setStartDate, setEndDate,
        isLegendTrue, setisLegendTrue,
        subtractDaysFromDate
    } = useContext(GraphDatesContext);
    const handleBtnSelection = (v) => {
        setBtnSelection(v);
        let today = new Date();
        let days = null;
        if (v == '1') {
            days = 30;
        }
        else if (v == '3') {
            days = 30 * 3;
        }
        else if (v == '6') {
            days = 30 * 6;
        }
        else if (v == '12') {
            days = 30 * 12;
        }
        else {
            console.log('Invalid value selected for date range');
            return
        }
        setEndDate(today);
        setStartDate(subtractDaysFromDate(today, days));
    }
    return (
        <div className='btn-toolbar' role='toolbar'>
            <div className='btn-group' role='group'>
                <button
                    className='btn'
                    onClick={() => handleBtnSelection('1')}
                >1m
                </button>
                <button
                    className='btn'
                    onClick={() => handleBtnSelection('3')}
                >3m
                </button>
                <button
                    className='btn'
                    onClick={() => handleBtnSelection('6')}
                >6m
                </button>
                <button
                    className='btn'
                    onClick={() => handleBtnSelection('12')}
                >12m
                </button>
            </div>
            <div className='btn-group' role='group'>
                <button className={`btn ${isLegendTrue ? 'btn-selected' : ''}`}
                    onClick={() => setisLegendTrue(!isLegendTrue)}
                >Legenda
                </button>
            </div>
        </div>
    )
}

export default Buttons;