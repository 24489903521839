import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import axios from 'axios';

import GraphDatesContext from '../GraphDatesContext';
import { useState, useEffect, useContext } from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json, text/plain, */*',
    },
}

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const Graph1 = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [chartOptions, setChartOptions] = useState({});

    const { startDate, endDate, getDateFormatForApi, getDateFormatForFrontend, isLegendTrue } = useContext(GraphDatesContext);

    const url = `${apiUrl}/endex/${getDateFormatForApi(startDate)}/${getDateFormatForApi(endDate)}`;

    useEffect(() => {
        axios.get(url, config)
            .then(response => {
                response = response.data;

                const labels = [];
                const datasetsOne = [];
                const datasetsTwo = [];
                const datasetsThree = [];
                const datasetsFour = [];
                const datasetsFive = [];

                response.forEach(item => {
                    labels.push(getDateFormatForFrontend(item.date));
                    datasetsOne.push(item.baseOneYear)
                    datasetsTwo.push(item.baseTwoYears)
                    datasetsThree.push(item.baseThreeYears)
                    datasetsFour.push(item.baseFourYears)
                    datasetsFive.push(item.baseFiveYears)
                })

                setChartData({
                    // labels: ['1 jaar', '2', '3', '4'],
                    labels: labels,
                    datasets: [{
                        label: '1 jaar',
                        data: datasetsOne,
                        fill: false,
                        borderColor: '#008080',
                        backgroundColor: '#008080',
                        tension: 0.1,
                        pointRadius: 0
                    },
                    {
                        label: '2 jaar',
                        data: datasetsTwo,
                        fill: false,
                        borderColor: '#FFD166',
                        backgroundColor: '#FFD166',
                        tension: 0.1,
                        pointRadius: 0
                    },
                    {
                        label: '3 jaar',
                        data: datasetsThree,
                        fill: false,
                        borderColor: '#F03A47',
                        backgroundColor: '#F03A47',
                        tension: 0.1,
                        pointRadius: 0
                    },
                    {
                        label: '4 jaar',
                        data: datasetsFour,
                        fill: false,
                        borderColor: '#AF5B5B',
                        backgroundColor: '#AF5B5B',
                        tension: 0.1,
                        pointRadius: 0
                    },
                    {
                        label: '5 jaar',
                        data: datasetsFive,
                        fill: false,
                        borderColor: '#25291C',
                        backgroundColor: '#25291C',
                        tension: 0.1,
                        pointRadius: 0
                    }],
                });
                setChartOptions({
                    responsive: true,
                    devicePixelRatio: 1.5,
                    aspectRatio: 1.6,
                    // maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: isLegendTrue,
                        },
                        title: {
                            display: true,
                            text: ['ENDEX Elektriciteit', `${getDateFormatForFrontend(startDate)} - ${getDateFormatForFrontend(endDate)}`]
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                label: function (context) {
                                    return context.dataset.label + ": " + Number(context.raw).toLocaleString("nl-NL") + " euro"
                                }
                            }
                        }
                    },
                    hover: {
                        mode: 'index'
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false
                            }
                        }
                    }
                });
            })
            .catch(err => {
                console.log(err);
            });
    }, [startDate, endDate, isLegendTrue]);

    return (
        <Line options={chartOptions} data={chartData} />
    );
}

export const Graph2 = () => {

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [chartOptions, setChartOptions] = useState({});

    const { startDate, endDate, getDateFormatForApi, getDateFormatForFrontend, isLegendTrue } = useContext(GraphDatesContext);

    const url = `${apiUrl}/epex/${getDateFormatForApi(startDate)}/${getDateFormatForApi(endDate)}`;

    useEffect(() => {
        axios.get(url, config)
            .then(response => {
                response = response.data;

                const labels = [];
                const datasetsOne = [];
                const datasetsTwo = [];

                response.forEach(item => {
                    labels.push(getDateFormatForFrontend(item.date));
                    datasetsOne.push(item.dayAheadPrice)
                    datasetsTwo.push(item.dayAheadPriceAverage)
                })

                setChartData({
                    // labels: ['20-daags gemiddelde', 'Variabel'],
                    labels: labels,
                    datasets: [{
                        label: '20-daags gemiddelde',
                        data: datasetsTwo,
                        fill: false,
                        borderColor: '#FFD166',
                        backgroundColor: '#FFD166',
                        tension: 0.1,
                        pointRadius: 0
                    },
                    {
                        label: 'Variabel',
                        data: datasetsOne,
                        fill: false,
                        borderColor: '#008080',
                        backgroundColor: '#008080',
                        tension: 0.1,
                        pointRadius: 0
                    }],
                });
                setChartOptions({
                    responsive: true,
                    devicePixelRatio: 1.5,
                    aspectRatio: 1.6,
                    // maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: isLegendTrue,
                        },
                        title: {
                            display: true,
                            text: ['EPEX Elektriciteit', `${getDateFormatForFrontend(startDate)} - ${getDateFormatForFrontend(endDate)}`]
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                label: function (context) {
                                    return context.dataset.label + ": " + Number(context.raw).toLocaleString("nl-NL") + " euro"
                                }
                            }

                        }
                    },
                    hover: {
                        mode: 'index'
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false
                            }
                        }
                    }
                });
            })
            .catch(err => {
                console.log(err);
            });
    }, [startDate, endDate, isLegendTrue]);

    return (
        <Line options={chartOptions} data={chartData} />
    );
}

export const Graph3 = () => {

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [chartOptions, setChartOptions] = useState({});

    const { startDate, endDate, getDateFormatForApi, getDateFormatForFrontend, isLegendTrue } = useContext(GraphDatesContext);

    const url = `${apiUrl}/ttf/${getDateFormatForApi(startDate)}/${getDateFormatForApi(endDate)}`;

    useEffect(() => {
        axios.get(url, config)
            .then(response => {
                response = response.data;

                const labels = [];
                const datasetsOne = [];
                const datasetsTwo = [];
                const datasetsThree = [];
                const datasetsFour = [];
                const datasetsFive = [];

                response.forEach(item => {
                    labels.push(getDateFormatForFrontend(item.date));
                    datasetsOne.push(item.priceOneYear)
                    datasetsTwo.push(item.priceTwoYears)
                    datasetsThree.push(item.priceThreeYears)
                    datasetsFour.push(item.priceFourYears)
                    datasetsFive.push(item.priceFiveYears)
                })

                setChartData({
                    // labels: ['1 jaar', '2', '3', '4'],
                    labels: labels,
                    datasets: [{
                        label: '1 jaar',
                        data: datasetsOne,
                        fill: false,
                        borderColor: '#008080',
                        backgroundColor: '#008080',
                        tension: 0.1,
                        pointRadius: 0
                    },
                    {
                        label: '2 jaar',
                        data: datasetsTwo,
                        fill: false,
                        borderColor: '#FFD166',
                        backgroundColor: '#FFD166',
                        tension: 0.1,
                        pointRadius: 0
                    },
                    {
                        label: '3 jaar',
                        data: datasetsThree,
                        fill: false,
                        borderColor: '#F03A47',
                        backgroundColor: '#F03A47',
                        tension: 0.1,
                        pointRadius: 0
                    },
                    {
                        label: '4 jaar',
                        data: datasetsFour,
                        fill: false,
                        borderColor: '#AF5B5B',
                        backgroundColor: '#AF5B5B',
                        tension: 0.1,
                        pointRadius: 0
                    },
                    {
                        label: '5 jaar',
                        data: datasetsFive,
                        fill: false,
                        borderColor: '#25291C',
                        backgroundColor: '#25291C',
                        tension: 0.1,
                        pointRadius: 0
                    }],
                });
                setChartOptions({
                    responsive: true,
                    devicePixelRatio: 1.5,
                    aspectRatio: 1.6,
                    // maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: isLegendTrue,
                        },
                        title: {
                            display: true,
                            text: ['Gas TTF', `${getDateFormatForFrontend(startDate)} - ${getDateFormatForFrontend(endDate)}`]
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                label: function (context) {
                                    return context.dataset.label + ": " + Number(context.raw).toLocaleString("nl-NL") + " euro"
                                }
                            }

                        }
                    },
                    hover: {
                        mode: 'index'
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false
                            }
                        }
                    }
                });
            })
            .catch(err => {
                console.log(err);
            });
    }, [startDate, endDate, isLegendTrue]);

    return (
        <Line options={chartOptions} data={chartData} />
    );
}

export const Graph4 = () => {

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [chartOptions, setChartOptions] = useState({});

    const { startDate, endDate, getDateFormatForApi, getDateFormatForFrontend, isLegendTrue } = useContext(GraphDatesContext);

    const url = `${apiUrl}/ttf-spot/${getDateFormatForApi(startDate)}/${getDateFormatForApi(endDate)}`;

    useEffect(() => {
        axios.get(url, config)
            .then(response => {
                response = response.data;

                const labels = [];
                const datasetsOne = [];

                response.forEach(item => {
                    labels.push(getDateFormatForFrontend(item.date));
                    datasetsOne.push(item.price)
                })

                setChartData({
                    // labels: ['Base'],
                    labels: labels,
                    datasets: [{
                        label: 'Base',
                        data: datasetsOne,
                        fill: false,
                        borderColor: '#008080',
                        backgroundColor: '#008080',
                        tension: 0.1,
                        pointRadius: 0
                    }],
                });
                setChartOptions({
                    responsive: true,
                    devicePixelRatio: 1.5,
                    aspectRatio: 1.6,
                    // maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: isLegendTrue,
                        },
                        title: {
                            display: true,
                            text: ['Gas Spot TTF', `${getDateFormatForFrontend(startDate)} - ${getDateFormatForFrontend(endDate)}`]
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                label: function (context) {
                                    return context.dataset.label + ": " + Number(context.raw).toLocaleString("nl-NL") + " euro"
                                }
                            }

                        }
                    },
                    hover: {
                        mode: 'index'
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false
                            }
                        }
                    }
                });
            })
            .catch(err => {
                console.log(err);
            });
    }, [startDate, endDate, isLegendTrue]);

    return (
        <Line options={chartOptions} data={chartData} />
    );
}