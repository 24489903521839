import TopBar from "./components/TopBar";
import { Graph1, Graph2, Graph3, Graph4 } from "./components/Graph";

import { GraphDatesProvider } from "./GraphDatesContext";

function App() {
    return (
        <GraphDatesProvider>
            <div className="container" style={{
                padding: '40px'
            }}>
                <TopBar />
                <div className="row graph-rows">
                    <h3 className="custom-font-2 pt-3 pb-3">Elektriciteit</h3>
                    <div className="col-12 col-lg-6">
                        <Graph1 />
                    </div>
                    <div className="col-12 col-lg-6">
                        <Graph2 />
                    </div>
                    <h3 className="custom-font-2 pt-3 pb-3">Gas</h3>
                    <div className="col-12 col-lg-6">
                        <Graph3 />
                    </div>
                    <div className="col-12 col-lg-6">
                        <Graph4 />
                    </div>
                </div>
            </div>
        </GraphDatesProvider>
    );
}

export default App;
