import Buttons from './Buttons';

// https://reactdatepicker.com/
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import GraphDatesContext from '../GraphDatesContext';
import { useContext } from 'react';

function TopBar() {
    const { startDate, setStartDate, endDate, setEndDate, todayDate } = useContext(GraphDatesContext);
    
    const customStyle = {
        marginBottom: 0
    }
    
    return (
        <div className='row'>
            <h2 className="custom-font">Actuele beursprijzen</h2>
            <div className='col-xs-12 col-lg-4 col-xl-3'>
                <p style={customStyle}>Van</p>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => { setStartDate(date) }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='dd-MMM-yyyy'
                />
            </div>
            <div className='col-xs-12 col-lg-4 col-xl-3'>
                <p style={customStyle}>Tot en met</p>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={todayDate}
                    dateFormat='dd-MMM-yyyy'
                />
            </div>
            <div className='col-xs-12 col-lg-12 col-xl-6'>
                <p style={customStyle}>Periode</p>
                <Buttons />
            </div>
        </div>
    );
}

export default TopBar;